import queryString from 'query-string'

export default function Filters() {
  let filterElement = jQuery('.sticky-list-filter > a')

  filterElement.each(function() {
    jQuery(this).click((e) => {
      e.preventDefault()
      let filter = jQuery(this).attr('data-filter')
      let value = jQuery(this).attr('data-value')
      let activeFilters = queryString.parse(window.location.search)

      if (typeof activeFilters[filter] == 'undefined') {
        // Filter is not currently applied. Apply it, with the rest of the filters added.
        let filterObj = {}
        filterObj[filter] = value

        let searchString = queryString.stringify(filterObj)

        if (window.location.search.length > 0) {
          searchString = `&${ searchString }`
        }

        window.location.search += searchString
        return false;
      }

      // Change values to arrays.
      for (let property in activeFilters) {
        if (typeof activeFilters[property] == 'string') {
          activeFilters[property] = [activeFilters[property]]
        }
      }

      // Handle changes to filters on click.
      let newFilters
      if (activeFilters[filter].indexOf(value) >= 0) {
        // Remove filter from existing filters and pass as params.
        newFilters = activeFilters[filter].filter(function(e) {
          return e !== value
        })
      } else {
        // Add filter to existing filters and pass as params.
        newFilters = activeFilters[filter].concat([value])
      }

      // Filters should stay the same, except for the one modified by the click.
      activeFilters[filter] = newFilters
      window.location.search = queryString.stringify(activeFilters)
    })
  })
}
