import mapboxgl from 'mapbox-gl'

function popUpHTML(properties) {
  const title = properties.title;
  const subtitle = properties.subtitle;
  const description = properties.description;
  const url = properties.url;

  return(
    `
      <div class="lf-mapbox-popup-container">
        <div class="lf-mapbox-title"><h6>${title}</h6></div>
        ${subtitle ? `<div class="lf-mapbox-subtitle">${subtitle}</div>` : ''}
        ${description ? `<div class="lf-mapbox-description">${description}</div>` : ''}
        ${url ?  `<div class="lf-mapbox-details"><a href="${url}" class="section-action"><span>Details</span> →</a></div>` : ''}
        </div>
    `
  )
}

function popUphandler(map, marker, marker_instance) {
  const content = jQuery(`#${marker.properties.title.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')}`);
  if (content) {
    marker_instance
    .setPopup(
      new mapboxgl.Popup({ offset: 25 })
        .setHTML(popUpHTML(marker.properties))
    );

    content.mouseenter(function() {
      marker_instance.togglePopup();
      map.flyTo({center: marker.geometry.coordinates})
    });

    content.mouseleave(function() {
      marker_instance.togglePopup();
    });
  }
}

export default function Maps() {
  if (jQuery('#map').length > 0) {
    const $mapContainer = jQuery('#map');

    let latitude = parseFloat($mapContainer.attr('data-latitude'))
    let longitude = parseFloat($mapContainer.attr('data-longitude'))
    const zoom = $mapContainer.attr('data-zoom') ? parseInt($mapContainer.attr('data-zoom')): 12;

    mapboxgl.accessToken = 'pk.eyJ1IjoidGphc3BlciIsImEiOiJjanUxbGQ5bjUwMnR5NDNsb2txZTB6Mmt5In0.Y6EwkeTuaidjJCOIBL5nMQ'
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/tjasper/cju1n96xw1ieh1fqm5qea4fhr',
      zoom: zoom,
      center: [longitude, latitude]
    })

    let features = [{
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [longitude, latitude]
      }
    }]

    const coordinates = $mapContainer.data('coordinates') || {};
    // coordinates = {0:(long: float, lat: float), 1:(long: float, lat: float), 2:(long: float, lat: float)}
    const popupContent = $mapContainer.data('content') || {};
    // content = {0: {title: str, subtitle: str, description: str}, 2: {title: str, sub-title: str, description: str}}
  
    const markersCount = Object.keys(coordinates).length;  
    const popupCount = Object.keys(popupContent).length;

    if(markersCount > 0) {
      features = []

      Object.keys(coordinates).forEach((key) => {
        features.push({
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": coordinates[key],
          },
          "properties": popupContent[key]
        });
      });
    }
    var geojson = {
      "type": "FeatureCollection",
      "features": features
    }

    map.on('load', () => {
      map.addSource("single-point", {
        "type": "geojson",
        "data": geojson
      })

      // map.addLayer({
      //   "id": "point",
      //   "source": "single-point",
      //   "type": "circle",
      //   "paint": {
      //     "circle-radius": 5,
      //     "circle-color": "#5AB7B2"
      //   }
      // })
      // add markers to map
      geojson.features.forEach(function(marker) {
        var el = document.createElement('div');
        el.className = 'mapbox-marker';
        const marker_instance = new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates);
        if (popupCount > 0 && marker.properties) {
          popUphandler(map, marker, marker_instance);
        }

        marker_instance.addTo(map);
      })
    })
  }
}
