export default function Blog() {
  let shareElement = jQuery('.lf-blog-share')
  let relatedElement = jQuery('.lf-blog-related-articles')

  if (shareElement.length > 0) {
    jQuery(window).scroll(function() {
      let scrollTop = jQuery(window).scrollTop()
      let scrollPercentage = scrollTop / jQuery('.page-content').height()
      let scrollBottom = scrollTop + jQuery(window).height()
      let containerBottom = jQuery(".lf-blog-share-container").offset().top + jQuery(".lf-blog-share").height()

      if (scrollPercentage > .5 && scrollBottom < containerBottom + 200) {
        relatedElement.addClass('sticky')
      } else {
        relatedElement.removeClass('sticky')
      }

      if (scrollPercentage > .66 && scrollBottom < containerBottom) {
        shareElement.addClass('sticky')
      } else {
        shareElement.removeClass('sticky')
      }
    });
  }
}
