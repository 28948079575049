import Pace from "pace-progress"
import UIkit from "uikit"

export default function () {
  Pace.start()
  Pace.on("done", () => {
    // Used to run the intro slide
    document.body.className = document.body.className.replace("is-firstrun", "")
    document.getElementById('app').classList.remove('app-loading')
    window.dispatchEvent(new Event('resize'));
    UIkit.update();
  })
}
