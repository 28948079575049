export default function Weather() {
  jQuery.get("/api/weather/current", function(data) {
    if (data.temperature) {
      let temp = `${ Math.round(data.temperature) }°C`
      jQuery("#current-weather").text(temp)
    }
    if (data.weather.icon) {
      let icon = data.weather.icon
      jQuery("#current-weather-icon").addClass(`icon-${ icon }`)
    }
  })
}
