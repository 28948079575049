import slick from 'slick-carousel'

export default function initHomeBanner() {
  const timeout = setTimeout(() => clearInterval(interval), 30000);
  const interval = setInterval(() => {
    // Check to see if header has loading
    if (jQuery('body.pace-done').length) {

      jQuery('.js-slick').on('afterChange', function(event, slick, currentSlide){
        setTimeout(function(){
          jQuery('.hero-slick .slick-slide:not(.slick-current) img').removeClass('uk-animation-kenburns');
        }, 250)
      }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
        jQuery('.js-slidenav-item').removeClass('uk-active').eq(nextSlide).addClass('uk-active');
        jQuery('.hero-slideContent').removeClass('is-active').eq(nextSlide).addClass('is-active');
        jQuery('.hero-location').removeClass('is-active').eq(nextSlide).addClass('is-active');
        // jQuery('.hero-slideContent').removeClass('is-active');
        // console.log(currentSlide, nextSlide)
        jQuery('.hero-slick .slick-slide:eq('+nextSlide+') img').addClass('uk-animation-kenburns');
      }).slick({
        dots: false,
        arrows: false,
        infinite: true,
        draggable: false,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 8000
      });

      jQuery('.js-slidenav-item').on('click', function(e){
        var slide = jQuery(this).index();
        // jQuery(this).parents('li').addClass('uk-active').isblings().removeClass('uk-active');
        jQuery('.js-slick').slick('slickGoTo', slide);
        e.preventDefault();
      });

      clearInterval(interval);
      clearTimeout(timeout);
    }
  }, 100);
}
