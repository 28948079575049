function headerDropdown(element) {
  this.element = element;
  this.mainNavigation = this.element.find('.header-mainnav');
  this.mainNavigationItems = this.mainNavigation.find('.has-dropdown');
  this.dropdownList = this.element.find('.header-dropdown-container');
  this.dropdownWrappers = this.dropdownList.find('.header-dropdown');
  this.dropdownItems = this.dropdownList.find('.header-dropdown-content');
  this.dropdownBg = this.dropdownList.find('.header-dropdown-background');
  this.mq = this.checkMq();
  this.bindEvents();
}

headerDropdown.prototype.checkMq = function() {
  //check screen size
  var self = this;
  return window.getComputedStyle(self.element.get(0), '::before').getPropertyValue('content').replace(/'/g, "").replace(/"/g, "").split(', ');
};

headerDropdown.prototype.bindEvents = function() {
  var self = this;
  //hover over an item in the main navigation
  this.mainNavigationItems.mouseenter(function(event) {
    //hover over one of the nav items -> show dropdown
    self.showDropdown($(this));
  }).mouseleave(function() {
    setTimeout(function() {
      //if not hovering over a nav item or a dropdown -> hide dropdown
      if (self.mainNavigation.find('.has-dropdown:hover').length == 0 && self.element.find('.header-dropdown-container:hover').length == 0) self.hideDropdown();
    }, 50);
  });

  //hover over the dropdown
  this.dropdownList.mouseleave(function() {
    setTimeout(function() {
      //if not hovering over a dropdown or a nav item -> hide dropdown
      (self.mainNavigation.find('.has-dropdown:hover').length == 0 && self.element.find('.header-dropdown-container:hover').length == 0) && self.hideDropdown();
    }, 50);
  });

  //click on an item in the main navigation -> open a dropdown on a touch device
  this.mainNavigationItems.on('touchstart', function(event) {
    var selectedDropdown = self.dropdownList.find('#' + $(this).data('content'));
    if (!self.element.hasClass('is-dropdown-visible') || !selectedDropdown.hasClass('active')) {
      event.preventDefault();
      self.showDropdown($(this));
    }
  });

  //on small screens, open navigation clicking on the menu icon
  this.element.on('click', '.nav-trigger', function(event) {
    event.preventDefault();
    self.element.toggleClass('nav-open');
  });
};

headerDropdown.prototype.showDropdown = function(item) {
  this.mq = this.checkMq();
  if (this.mq == 'desktop') {
    var self = this;
    var selectedDropdown = this.dropdownList.find('#' + item.data('content')),
      selectedDropdownHeight = selectedDropdown.innerHeight(),
      selectedDropdownWidth = selectedDropdown.children('.header-dropdown-content').innerWidth(),
      selectedDropdownLeft = item.offset().left + item.innerWidth() / 2 - selectedDropdownWidth / 2;

    //update dropdown position and size
    // console.log(parseInt(selectedDropdownHeight), selectedDropdownWidth, parseInt(selectedDropdownLeft))
    this.updateDropdown(selectedDropdown, parseInt(selectedDropdownHeight), selectedDropdownWidth, parseInt(selectedDropdownLeft));
    //add active class to the proper dropdown item
    this.element.find('.active').removeClass('active');
    selectedDropdown.addClass('active').removeClass('move-left move-right').prevAll().addClass('move-left').end().nextAll().addClass('move-right');
    item.addClass('active');
    //show the dropdown wrapper if not visible yet
    if (!this.element.hasClass('is-dropdown-visible')) {
      setTimeout(function() {
        self.element.addClass('is-dropdown-visible');
      }, 10);
    }
  }
};

headerDropdown.prototype.updateDropdown = function(dropdownItem, height, width, left) {
  // this.dropdownList.css({
  //   // '-moz-transform': 'translateX(' + left + 'px)',
  //   // '-webkit-transform': 'translateX(' + left + 'px)',
  //   // '-ms-transform': 'translateX(' + left + 'px)',
  //   // '-o-transform': 'translateX(' + left + 'px)',
  //   // 'transform': 'translateX(' + left + 'px)',
  //   // 'width': width+'px',
  //   // 'height': height+'px'
  // });

  this.dropdownBg.css({
    // '-moz-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
    // '-webkit-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
    // '-ms-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
    // '-o-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
    // 'transform': 'scaleX(' + width + ') scaleY(' + height + ')'
    'width': width,
    'height': height
  });
};

headerDropdown.prototype.hideDropdown = function() {
  this.mq = this.checkMq();
  if (this.mq == 'desktop') {
    this.element.removeClass('is-dropdown-visible').find('.active').removeClass('active').end().find('.move-left').removeClass('move-left').end().find('.move-right').removeClass('move-right');
  }
};

headerDropdown.prototype.resetDropdown = function() {
  this.mq = this.checkMq();
  this.updateDropdown();
  if (this.mq == 'mobile') {
    this.dropdownList.removeAttr('style');
  }
};

function closeMenu() {
  $('.megamenu-left').removeClass('is-active');
  $('.megamenu-right').removeClass('is-active');
  $('.megamenu-utilholder').removeClass('is-active');
  $('.megamenu').removeClass('is-open');
  $('.megamenu').removeClass('is-rightactive');
  $('.megamenu-link, .megamenu-target').removeClass('is-active');
  $('html').removeClass('is-noscroll');
}

export default function initHeader() {
  var headerDropdowns = [];
  if ($('.header-navbar').length > 0) {
    $('.header-navbar').each(function() {
      //create a headerDropdown object for each .header-dropdown
      headerDropdowns.push(new headerDropdown($(this)));
    });

    var resizing = false;

    //on resize, reset dropdown style property
    updateDropdownPosition();
    $(window).on('resize', function() {
      if (!resizing) {
        resizing = true;
        (!window.requestAnimationFrame) ? setTimeout(updateDropdownPosition, 300) : window.requestAnimationFrame(updateDropdownPosition);
      }
    });

    function updateDropdownPosition() {
      headerDropdowns.forEach(function(element) {
        element.resetDropdown();
      });

      resizing = false;
    }
    ;
  }

  // const timeout = setTimeout(() => clearInterval(interval), 30000);
  // const interval = setInterval(() => {
  //   // Check to see if header has loading
  //   if ($('body.pace-done').length) {
  //     $('.js-dropdown-cards').slick({
  //       nextArrow: '<button type="button" class="slick-next"><svg><use xlink:href="#right-arrow"></use></svg></button>',
  //       infinite: true,
  //       dots: false,
  //       slidesToShow: 4,
  //       slidesToScroll: 3,
  //       responsive: [
  //         {
  //           breakpoint: 1500,
  //           settings: {
  //             slidesToShow: 3,
  //             slidesToScroll: 2,
  //             infinite: true,
  //           }
  //         },
  //         {
  //           breakpoint: 1300,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1,
  //             infinite: true,
  //           }
  //         },
  //         {
  //           breakpoint: 1100,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1,
  //             infinite: true,
  //           }
  //         },
  //         {
  //           breakpoint: 1000,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1
  //           }
  //         },
  //         {
  //           breakpoint: 480,
  //           settings: {
  //             slidesToShow: 2,
  //             slidesToScroll: 1
  //           }
  //         }
  //       ]
  //     });
  //     clearInterval(interval);
  //     clearTimeout(timeout);
  //   }
  // }, 100);

  $(document).on('click', '.js-open-megamenu', function(e) {
    var target = $(this).attr('href')
    $('.megamenu').addClass('is-open');
    $('html').addClass('is-noscroll');

    setTimeout(function() {
      $('.megamenu-link[href="' + target + '"]').addClass('is-active');
      $(target).addClass('is-active');
    }, 500)

    e.preventDefault();
  })

  // $(document).on('click', '.megamenu-link', function(e) {
  //   var target = $(this).attr('href')
  //   $('.megamenu').addClass('is-rightactive');
  //   $('.megamenu-left').addClass('is-active');
  //   $('.megamenu-utilholder').addClass('is-active');
  //   $('.megamenu-link').removeClass('is-active');
  //   $('.megamenu-right').addClass('is-active');
  //   $('.megamenu-link[href="' + target + '"]').addClass('is-active');
  //   $(target).addClass('is-active').siblings().removeClass('is-active');
  //   e.preventDefault();
  // })

  $(document).on('click', '.megamenu a:not(.megamenu-link)', function(e) {
    closeMenu()
  })

  $(document).on('click', '.megamenu-back', function(e) {
    $('.megamenu-left').removeClass('is-active');
    $('.megamenu-right').removeClass('is-active');
    $('.megamenu-utilholder').removeClass('is-active');
    $('.megamenu-link').removeClass('is-active');
    $('.megamenu-target').removeClass('is-active');
    $('.megamenu').removeClass('is-rightactive');
    e.preventDefault();
  })

  $(document).on('click', '.megamenu-close', function(e) {
    closeMenu()
    e.preventDefault();
  })
}
