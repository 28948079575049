// import Instafeed from 'instafeed.js/instafeed.js'
import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise"
import UIkit from "uikit"

export default function ShareLinks() {
  let pageTitle = jQuery('title').text()
  pageTitle = pageTitle.split(' - ')[0]
  // Strip out non-allowed characters
  // pageTitle = pageTitle[0].replace(/[^a-zA-Z0-9 ]/g, '')

  let twitter = jQuery("[data-share='twitter']")
  let facebook = jQuery("[data-share='facebook']")
  let clippy = jQuery("[data-share='clipboard']")

  twitter.attr("href", "https://twitter.com/share?url=" + window.location.href + '&via=TourismJasper&text=' + pageTitle)
  twitter.attr("target", "_blank")
  facebook.attr("href", "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href)
  facebook.attr("target", "_blank")
  clippy.attr("href", "#")
  clippy.click(function(e) {
    clipboard.writeText(`${ window.location.href }`)
    let tooltip = UIkit.tooltip(this, {
      title: 'Copied!'
    })
    tooltip.show()
    let timeout = setTimeout(function() {
      tooltip.$destroy();
    }, 1000)
    jQuery(this).mouseleave(function() {
      clearTimeout(timeout)
      tooltip.$destroy();
    })
    e.preventDefault()
  })
}
