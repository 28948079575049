import algoliasearch from 'algoliasearch';
import autocomplete from 'autocomplete.js';

const dev = process.env.NODE_ENV === 'development';

// https://github.com/algolia/algoliasearch-client-javascript
const applicationId = dev ? '3CHAXJ5JTW' : 'VKNKIQ6P7A';
const apiKey = dev ? '8bbd4998a6a7777c5e621540907e2d92' : '0bad5299242f18777d2ef66ccfbf271c';
const client = algoliasearch(applicationId, apiKey);

const pageIndex = client.initIndex('page_index');
const experienceIndex = client.initIndex('experience_index');
const accommodationIndex = client.initIndex('accommodation_index');
const diningIndex = client.initIndex('dining_index');
const shareholdersIndex = client.initIndex('shareholders_index');
const blogIndex = client.initIndex('blog_index');
const listIndex = client.initIndex('list_index');
const itineraryIndex = client.initIndex('itinerary_index');

var canToggle = true;

const globalAutocompleteOptions = {
  // autoWidth: false,
  debug: dev,
  cssClasses: {
    root: 'algolia-autocomplete algolia-autocomplete--global',
  },
};

function suggestionTemplate(suggestion) {
  // for the categories in here we use isNaN to only display
  // tags that cannot be cast into integers
  return `
    <a class="aa-suggestion-link uk-link-reset" :to="'${suggestion.url}'">
      <span class="aa-suggestion-title">${suggestion._highlightResult.title.value}</span>
      ${suggestion._tags ? `
        <span class="aa-suggestion-categories">
          <span>${suggestion._tags[0]}</span>
        </span>
      ` : ''}
    </a>
  `;
}

function updateLocation(dataset, suggestion) {
  // Follow link to page when user hits enter key while item is selected
  location.href = suggestion.url;
}

function toggleSearch(search) {
  function toggle() {
    if (jQuery("#search-field").attr("hidden") == "hidden") {
      // expand
      jQuery("#search-field").removeAttr("hidden")
      jQuery("#search-field").removeAttr("value")
      jQuery(".js-search").removeClass("has-content")
      setTimeout(function() {
        jQuery("#search-field").removeClass("collapsed")
        jQuery(".lf-search").removeClass("collapsed")
        jQuery(".header-navitem").not(".js-search").css("opacity", 0)
        jQuery(".header-navitem").not(".js-search").css("pointer-events", "none")
        jQuery(".header-utillink").addClass("header-hide")
        jQuery(".js-search").addClass("opened")
      }, 1)
      setTimeout(function() {
        jQuery(".aa-dropdown-menu").removeAttr("hidden")
        canToggle = true;
      }, 500)
    } else {
      // collapse
      jQuery("#search-field").addClass("collapsed")
      jQuery(".lf-search").addClass("collapsed")
      jQuery(".aa-dropdown-menu").attr("hidden", "hidden")
      jQuery(".header-navitem").not(".js-search").css("opacity", 1)
      jQuery(".header-navitem").not(".js-search").css("pointer-events", "all")
      jQuery(".header-utillink").removeClass("header-hide")
      jQuery(".js-search").removeClass("opened")
      setTimeout(function() {
        jQuery("#search-field").attr("hidden", "hidden")
        search.autocomplete.setVal("");
        canToggle = true;
      }, 500)
    }
  }

  jQuery("#search-field").on("input", function() {
    if (jQuery(this).val()) {
      jQuery(".js-search").addClass("has-content")
    } else {
      jQuery(".js-search").removeClass("has-content")
    }
  })

  jQuery("#search-toggle").click(function() {
    // Debounce toggles.
    if (canToggle) {
      jQuery("#search-toggle > *").toggle()
      canToggle = false;
    } else {
      return
    }

    toggle()
  });
}

export default function Search() {
  let autocompleteDivs = document.querySelectorAll('.algolia-autocomplete').length;
  if (autocompleteDivs > 0) {
    return
  }

  const search = autocomplete('#search-field', globalAutocompleteOptions,
    [
      {
        // define custom source (see: https://github.com/algolia/autocomplete.js#custom-source)
        source(query, callback) {
          pageIndex.search(query, {
            hitsPerPage: 5
          }).then((answer) => {
            callback(answer.hits);
          }, () => {
            callback([]);
          });
        },
        displayKey: 'title',
        templates: {
          header: '<div class="aa-suggestions-category">Page</div>',
          suggestion(suggestion) {
            return suggestionTemplate(suggestion);
          },
        },
      }, {
        // define custom source (see: https://github.com/algolia/autocomplete.js#custom-source)
        source(query, callback) {
          experienceIndex.search(query, {
            hitsPerPage: 5
          }).then((answer) => {
            callback(answer.hits);
          }, () => {
            callback([]);
          });
        },
        displayKey: 'title',
        templates: {
          header: '<div class="aa-suggestions-category">Experiences</div>',
          suggestion(suggestion) {
            return suggestionTemplate(suggestion);
          },
        },
      }, {
        // define custom source (see: https://github.com/algolia/autocomplete.js#custom-source)
        source(query, callback) {
          accommodationIndex.search(query, {
            hitsPerPage: 5
          }).then((answer) => {
            callback(answer.hits);
          }, () => {
            callback([]);
          });
        },
        displayKey: 'title',
        templates: {
          header: '<div class="aa-suggestions-category">Accommodations</div>',
          suggestion(suggestion) {
            return suggestionTemplate(suggestion);
          },
        },
      }, {
        // define custom source (see: https://github.com/algolia/autocomplete.js#custom-source)
        source(query, callback) {
          diningIndex.search(query, {
            hitsPerPage: 5
          }).then((answer) => {
            callback(answer.hits);
          }, () => {
            callback([]);
          });
        },
        displayKey: 'title',
        templates: {
          header: '<div class="aa-suggestions-category">Dining</div>',
          suggestion(suggestion) {
            return suggestionTemplate(suggestion);
          },
        },
      }, {
        // define custom source (see: https://github.com/algolia/autocomplete.js#custom-source)
        source(query, callback) {
          shareholdersIndex.search(query, {
            hitsPerPage: 5
          }).then((answer) => {
            callback(answer.hits);
          }, () => {
            callback([]);
          });
        },
        displayKey: 'title',
        templates: {
          header: '<div class="aa-suggestions-category">Directory</div>',
          suggestion(suggestion) {
            return suggestionTemplate(suggestion);
          },
        },
      }, {
        // define custom source (see: https://github.com/algolia/autocomplete.js#custom-source)
        source(query, callback) {
          blogIndex.search(query, {
            hitsPerPage: 5
          }).then((answer) => {
            callback(answer.hits);
          }, () => {
            callback([]);
          });
        },
        displayKey: 'title',
        templates: {
          header: '<div class="aa-suggestions-category">Blog</div>',
          suggestion(suggestion) {
            return suggestionTemplate(suggestion);
          },
        },
      }, {
        // define custom source (see: https://github.com/algolia/autocomplete.js#custom-source)
        source(query, callback) {
          listIndex.search(query, {
            hitsPerPage: 2
          }).then((answer) => {
            callback(answer.hits);
          }, () => {
            callback([]);
          });
        },
        displayKey: 'title',
        templates: {
          header: '<div class="aa-suggestions-category">Lists</div>',
          suggestion(suggestion) {
            return suggestionTemplate(suggestion);
          },
        }
      }, {
        // define custom source (see: https://github.com/algolia/autocomplete.js#custom-source)
        source(query, callback) {
          itineraryIndex.search(query, {
            hitsPerPage: 2
          }).then((answer) => {
            callback(answer.hits);
          }, () => {
            callback([]);
          });
        },
        displayKey: 'title',
        templates: {
          header: '<div class="aa-suggestions-category">Itineraries</div>',
          suggestion(suggestion) {
            return suggestionTemplate(suggestion);
          },
        },
      }
    ],
  );

  search.on('autocomplete:selected', updateLocation);
  search.on('autocomplete:selected', function() {
    search.autocomplete.setVal("");
  });

  toggleSearch(search)

  let lastScroll = 0
  $(window).scroll((e) => {
    if ($(window).scrollTop() > lastScroll && lastScroll != 0) {
      jQuery("#search-toggle > *").first().show()
      jQuery("#search-toggle > *").last().hide()
      jQuery("#search-field").addClass("collapsed")
      jQuery(".lf-search").addClass("collapsed")
      jQuery(".aa-dropdown-menu").attr("hidden", "hidden")
      jQuery(".header-navitem").not(".js-search").css("opacity", 1)
      jQuery(".header-navitem").not(".js-search").css("pointer-events", "all")
      jQuery(".header-utillink").removeClass("header-hide")
      jQuery(".js-search").removeClass("opened")
      setTimeout(function() {
        jQuery("#search-field").attr("hidden", "hidden")
        search.autocomplete.setVal("");
      }, 500)
    }
    lastScroll = $(window).scrollTop()
  })
}
