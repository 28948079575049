// 3rd Party
import UIkit from 'uikit';
// import Icons from 'uikit/dist/js/uikit-icons'
import jQuery from "jquery"

// Modules
import Greeting from "./modules/hello"
// import AjaxForms from "./modules/ajaxforms"
import Progress from "./modules/pace-progress"
import Header from "./modules/header"
import HomeBanner from "./modules/homeBanner"
import Blog from "./modules/blog"
import Filters from "./modules/filters"
// import Slider from "./modules/slider"
import DetailSlideshow from "./modules/detailSlideshow"
import ShareLinks from "./modules/sharing"
import Search from "./modules/search"
import Weather from "./modules/weather"
import Maps from "./modules/maps"
import SlideIn from "./modules/slidein"
// import Instagram from "./modules/instagram.js"

// Vue options
// import Vue from 'vue'
// import App from './components/Planner'
// import Favourite from './components/Favourite'
// import WeatherWidget from './components/Weather'
// import Vuex from 'vuex'
// import store from './store'

// Vue.config.productionTip = false;
// Vue.use(Vuex);


const site = {
  /*
   * Initialises the site's modules.
   * Each module defines its own init function, this is just the glue.
   */
  init() {
    Greeting()
    // AjaxForms()
    // Progress()
    Header()
    HomeBanner()
    Blog()
    Filters()
    // Slider()
    ShareLinks()
    Search()
    Weather()
    Maps()
    SlideIn()
    DetailSlideshow()
    stickyAccordion()
    // Instagram()

    // const timeout = setTimeout(() => clearInterval(interval), 30000);
    // const interval = setInterval(() => {
    //   // Check to see if header has loading
    //   if ($('body.pace-done').length) {
    //     UIkit.update();
    //     window.dispatchEvent(new Event('resize'));
    //     clearInterval(interval);
    //     clearTimeout(timeout);
    //   }
    // }, 100);



    window.addEventListener('resize', function(event) {
      // console.log('resized');
      // window.dispatchEvent(new Event('scroll'))
      UIkit.update();
    });
  },
}

// const vueApp = new Vue({
//   el: '#vue',
//   template: '<App ref="planner" />',
//   components: {
//     App
//   },
//   store
// })

// if (jQuery("#weather").length > 0) {
//   const weatherApp = new Vue({
//     el: '#weather',
//     template: '<WeatherWidget />',
//     components: {
//       WeatherWidget
//     },
//     store
//   })
// }

// // Favourite buttons
// function vueButtons() {
//   jQuery('.experience-favourite-unloaded').each(function() {
//     if (!jQuery(this).hasClass('favourited')) {
//       let id = jQuery(this).attr('data-id')
//       let path = jQuery(this).attr('data-path')
//       let title = jQuery(this).attr('data-title')
//       let favouriteButton = new Vue({
//         el: this,
//         template: `<Favourite :id='${ id }' :path='"${ path }"' :title='"${ title }"' />`,
//         components: {
//           Favourite
//         },
//         store
//       })
//     }
//   })
// }
// jQuery(window).on('load', function() {
//   console.log('hello');
//   vueButtons();
// })
// jQuery(window).on('paginationLoad', function() {
//   vueButtons()
//   stickyAccordion()
// })
function stickyAccordion(){
  jQuery('.lf-mobile-chev').click(function(){
    if(jQuery(this).hasClass('active')){
      jQuery(this).removeClass('active');
      jQuery(this).find('use').attr('xlink:href','#chev-down');
      jQuery(this).parent().siblings('.lf-subcat-dropdown').slideToggle();
    }else{
      jQuery(this).addClass('active');
      jQuery(this).find('use').attr('xlink:href','#chev-up');
      jQuery(this).parent().siblings('.lf-subcat-dropdown').slideToggle();
    }
    
  });
}



// window.app = vueApp
site.init();
