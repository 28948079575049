import Cookies from 'js-cookie'

export default function SlideIn() {
  if (jQuery('.lf-slide-in').length > 0) {
    let slideIns = jQuery('.lf-slide-in')
    let closeButtons = jQuery('.lf-slide-in-close')
    closeButtons.click(function() {
      Cookies.set('newsletterDismissed', true)
      jQuery('.lf-slide-in').addClass('uk-hidden')
    })
    let dismissed = Cookies.get('newsletterDismissed')
    if (!dismissed) {
      slideIns.removeClass('uk-hidden')
    }
    jQuery(window).on('resize', function() {
      jQuery(".lf-floating-slide-in-mobile #mc-embedded-subscribe-form").css("margin-bottom", jQuery("#iubenda-cs-banner").height())
    })
    jQuery(window).on('load', function() {
      jQuery(".lf-floating-slide-in-mobile #mc-embedded-subscribe-form").css("margin-bottom", jQuery("#iubenda-cs-banner").height())
      jQuery(".iubenda-cs-close-btn, a, form").click(function() {
        jQuery(".lf-floating-slide-in-mobile #mc-embedded-subscribe-form").css("margin-bottom", 0)
      })
    })
  }
}
