import slick from 'slick-carousel'

export default function DetailSlideshow() {
  jQuery(window).on("load", function() {
    let slider = jQuery('.js-detailSlideshow').find('.detail-slideshow-items').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      nextArrow: jQuery('.js-detailSlideshow').find('.detail-gallery-left')[0],
      prevArrow: jQuery('.js-detailSlideshow').find('.detail-gallery-right')[0],
    });
  })
}
